/*************************************************************************
*
* © 2020 Elo Touch Solutions, Inc
*
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Elo Touch Solutions, Inc and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Elo Touch Solutions, Inc
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Elo Touch Solutions, Inc.
*************************************************************************/

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import rootReducers from './redux/rootReducers';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import PreLoader from './Common/PreLoader';
import ReactGA from 'react-ga';

const AppComponent = React.lazy(() => import('./App'));

export const store = createStore(rootReducers, applyMiddleware(thunk));
if (window.location.href.includes('staging'))
  ReactGA.initialize(process.env?.REACT_APP_STG_GA_ID ?? '');
else
  ReactGA.initialize(process.env?.REACT_APP_PROD_GA_ID ?? '');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PreLoader />}>
          <AppComponent />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
