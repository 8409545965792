/*************************************************************************
*
* © 2020 Elo Touch Solutions, Inc
*
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Elo Touch Solutions, Inc and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Elo Touch Solutions, Inc
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Elo Touch Solutions, Inc.
*************************************************************************/

import React from 'react';
import './UiComponents/uicomponent.css';
import logo from '../Images/elo-logo-color.png';

/**
 * PreLoader Component will be render whenever the page refresh
 * 
 * @component
 * 
 */


function PreLoader(props: any) : JSX.Element{
    return (
        <div className="preloader_overlay">
            <div id="preloader">
                <div id="preloader-box">
                    <img src={logo} alt='logo' className="preloadericon" />
                </div>
                <div className="preloader-extracls preloader-center">
                    <div className="preloader-loading">
                        <div className="preloading-bar"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PreLoader;
