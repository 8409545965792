/*************************************************************************
 *
 * © 2020 Elo Touch Solutions, Inc
 *
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elo Touch Solutions, Inc and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Elo Touch Solutions, Inc
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Elo Touch Solutions, Inc.
 *************************************************************************/

import { Survey, Questions, Splash } from "../../Common/types";

export function surveyList(
  state = { splash: {}, questions: {},  usage:0 },
  action: { type: string; data: Survey }
): Survey | { splash: Splash; questions: Questions; [key: string]: any, usage:number } {
  const survey = action.data;
  const sortedQuestions: Questions = {};
  switch (action.type) {
    case "SURVEY_LIST":
      if (action.data.questions) {
        Object.keys(survey.questions)
          .sort((a, b) => {
            if(survey.questions[a].type=='identity')
            return -1;

            return survey.questions[a].order - survey.questions[b].order;
          })
          .map(function (key) {
            sortedQuestions[key] = survey.questions[key];
            return sortedQuestions;
          });
      }
      survey.questions = sortedQuestions;
      return survey;
    default:
      return state;
  }
}
