/*************************************************************************
*
* © 2020 Elo Touch Solutions, Inc
*
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Elo Touch Solutions, Inc and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Elo Touch Solutions, Inc
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Elo Touch Solutions, Inc.
*************************************************************************/

import { combineReducers } from 'redux';
import { surveyList } from './reducers/surveyList';

const rootReducer = combineReducers({
    surveyList,
});

export default rootReducer;
